.graph {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 51;
  z-index: 0;
  left: 0;
  bottom: 51;
  right: 0;
}
