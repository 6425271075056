@import '../../variables';

.main_wrapper{
  padding: 0px 43px;
}

.header_wrapper {
  background-color: $color-1;
  width: 100%;
  display: -webkit-flex;
  a{
    text-decoration: none;
  }

  .brand{
    color: #F9F9F9;
    margin: auto 12px;
    font-size: 24px;
    font-family: 'Play', sans-serif;
  }

  img{
    height: 30px;
    margin: auto 0px;
  }


  
  .options{
    margin-left: auto;
    button {
      color: #fff;
      font-size: 28px;
      height:100%;
      // background-color: #1587B8;
      border-radius: 0;
    }
  }
  
}


.content_wrapper {


}

@media (max-width: 865px){

}