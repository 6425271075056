body {
  margin: 0;
  padding: 0;
  background-color: #F9F9F9;
  font-family: Play, sans-serif !important;
  
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

button,input, input[type="submit"], input[type="reset"] {
	background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
}

.float_right {
  float:right;
}