.transition-dialog{
	.flex{
		display:flex;
		&.column{
			flex-direction: column;
		}
	}

	.select-field{
		min-width:120px;
	}
	.source-target-div{
		padding-top:10px;
		:first-child{
			padding-right:10px;
		}
	}

	.delete-button{
		position:absolute;
		right: 10px;
		top:13px;
	}
}